<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      :width="img_info.dialogWidth + '%'"
      :before-close="handleClose"
      :style="{ transform: dialogTransform }"
    >
      <span slot="title">
        <div
          class="avue-crud__dialog__header"
          @mousedown="dialogMouseDown"
          @mousemove="dialogMouseMove"
          @mouseleave="dialogMouseLeave"
          @mouseup="dialogMouseUp"
        >
          <span class="el-dialog__title">
            <span
              style="
                color: #409eff;
                font-size: 18px;
                display: inline-block;
                background-color: #3478f5;
                width: 3px;
                height: 20px;
                margin-right: 5px;
                float: left;
                margin-top: 2px;
              "
            ></span>
            {{ '图片预览' }}
          </span>

          <div class="avue-crud__dialog__menu">
            <el-button plain size="small" @click="imgSpinLeft">
              <i class="el-icon-refresh-left"></i
            ></el-button>
            <el-button plain size="small" @click="imgSpinRight">
              <i class="el-icon-refresh-right"></i
            ></el-button>
            <el-button plain size="small" @click="imgSmall">
              <i class="el-icon-minus"></i
            ></el-button>
            <el-button plain size="small" @click="imgBig"> <i class="el-icon-plus"></i></el-button>
          </div>
        </div>
      </span>
      <el-carousel
        ref="remarkCarusel"
        :trigger="trigger"
        :loop="loop"
        :arrow="arrow"
        :autoplay="autoplay"
        :interval="interval"
        :initial-index="img_index"
        :height="img_info.imgHeight + 'px'"
        @change="switchFn"
      >
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
          <div class="img-title">
            <span
              ><span style="color: #409eff">目前展示图片名称: </span
              ><span>{{ item.fileName }}</span></span
            >
            <el-button size="mini" type="primary"
              ><a download @click="downloadFn(item)">下载</a></el-button
            >
          </div>
          <!-- @mousewheel.stop="onMouseWheel" -->
          <div class="center">
            <div
              class="img-center"
              @mousewheel="handerPictu"
              @mousedown="onMouseDown"
              @mousemove="onMouseMove"
              @mouseleave="onMouseLeave"
              @mouseup="onMouseUp"
            >
              <img
                :style="{ transform: imageTransform }"
                ref="image"
                :src="filepath + item.filePath"
                height="100%"
              />
              <!-- <el-image :src="filepath + item.filePath"></el-image> -->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    img_index: {
      type: Number,
      default: 0,
    },
    info: {
      type: Object,
      default: function () {
        return {
          dialogWidth: 60,
          imgHeight: 550,
        }
      },
    },
    filepath: {
      type: String,
      default: '',
    },
    imgList: {
      type: Array,
      default: function () {
        return []
      },
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: String,
      default: 'hover',
    },
    interval: {
      type: Number,
      default: 0,
    },
    trigger: {
      type: String,
      default: 'click',
    },
  },
  data() {
    return {
      img_info: this.info || {
        dialogWidth: 60,
        imgHeight: 550,
      },
      scale: 1,
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      imageLeft: 0,
      imageTop: 0,
      startImageX: 0,
      startImageY: 0,
      imgDeg: 0,
      // 弹窗
      isdialogDragging: false,
      dialogStartX: 0,
      dialogStartY: 0,
      dialogLeft: 0,
      dialogTop: 0,
      startDialogX: 0,
      startDialogY: 0,
      dialogDeg: 0,
      dialogScale: 1,
    }
  },
  watch: {
    dialogVisible: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.$refs.remarkCarusel) {
            this.$refs.remarkCarusel.setActiveItem(this.img_index)
          }
        }
      },
    },
  },
  computed: {
    imageTransform() {
      return `translate(${this.imageLeft}px, ${this.imageTop}px) scale(${this.scale}) rotate(${this.imgDeg}deg) `
    },
    dialogTransform() {
      return `translate(${this.dialogLeft}px, ${this.dialogTop}px) `
    },
  },
  methods: {
    dialogMouseDown(event) {
      this.isdialogDragging = true
      this.dialogStartX = event.clientX
      this.dialogStartY = event.clientY
      this.startDialogX = this.dialogLeft
      this.startDialogY = this.dialogTop
    },
    dialogMouseMove(event) {
      event.preventDefault()
      if (this.isdialogDragging) {
        const offsetX = event.clientX - this.dialogStartX
        const offsetY = event.clientY - this.dialogStartY
        this.dialogLeft = this.startDialogX + offsetX
        this.dialogTop = this.startDialogY + offsetY
      }
    },
    dialogMouseUp() {
      this.isdialogDragging = false
    },
    dialogMouseLeave() {
      this.isdialogDragging = false
    },
    imgSpinLeft() {
      this.imgDeg -= 90
    },
    imgSpinRight() {
      this.imgDeg += 90
    },
    switchFn() {
      setTimeout(() => {
        this.imageLeft = 0
        this.imageTop = 0
        this.scale = 1
        this.imgDeg = 0
      }, 250)
    },
    onMouseDown(event) {
      this.isDragging = true
      this.dragStartX = event.clientX
      this.dragStartY = event.clientY
      this.startImageX = this.imageLeft
      this.startImageY = this.imageTop
    },
    onMouseMove(event) {
      event.preventDefault()
      if (this.isDragging) {
        const offsetX = event.clientX - this.dragStartX
        const offsetY = event.clientY - this.dragStartY
        this.imageLeft = this.startImageX + offsetX
        this.imageTop = this.startImageY + offsetY
      }
    },
    onMouseUp() {
      this.isDragging = false
    },
    onMouseLeave() {
      this.isDragging = false
    },
    handerPictu(e) {
      e.preventDefault()
      const wheelDelta = e.deltaY
      if (wheelDelta > 0 && this.scale > 0.4) {
        this.scale -= 0.1
      } else if (wheelDelta < 0) {
        this.scale += 0.1
      }
    },

    imgBig() {
      this.scale += 0.1
    },
    imgSmall() {
      if (this.scale > 0.4) {
        this.scale -= 0.1
      }
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/png') //得到图片的base64编码数据

        var a = document.createElement('a') // 生成一个a元素
        // var event = new MouseEvent('click') // 创建一个单击事件
        a.download = name || '图片' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.click()
        a.remove()
        // a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = imgsrc
    },
    downloadFn(item) {
      // 执行下载操作
      let downloadUrl = ''
      if (process.env.NODE_ENV == 'development') {
        //如果是dev环境
        downloadUrl = 'http://' + window.location.host + item.filePath
      } else {
        downloadUrl = this.filepath + item.filePath
      }
      const fileName = item.fileName

      this.downloadIamge(downloadUrl, fileName)
    },
    handleClose() {
      this.$emit('update:dialogVisible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.img-title {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 3px;
  .download {
    // color: #409eff;
    &:hover {
      cursor: pointer;
    }
  }
}
.center {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.img-center {
  // display: flex;
  // align-content: center;
  // justify-content: center;
  // height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
/*  dialog*/
/deep/.el-dialog__header {
  padding: 15px 20px 15px;
}
/deep/.el-dialog__headerbtn {
  top: 15px;
}

/*dialog header*/
/deep/.el-dialog__header {
  background: #e3eaed;
}
/deep/.avue-crud__dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgb(199, 226, 247);
  padding: 5px 0;
}
/deep/.el-dialog__title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  word-wrap: break-word;
}
/deep/.avue-crud__dialog__menu {
  padding-right: 35px;
  float: left;
}
/deep/.avue-crud__dialog__menu i {
  color: #909399;
  font-size: 15px;
}
/deep/.el-icon-full-screen {
  cursor: pointer;
}
/deep/.el-icon-full-screen:before {
  content: '\e719';
}
</style>
